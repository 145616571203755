.fig-pre {
  font-size: 5px;
  text-align: start;
  height: 264px;
}

.nav-ul {
  align-items: baseline;
  font-size: 12pt;
}

div.unfold > ul {
  list-style: none;
}

div.unfold > ul > li {
  font-size: 10pt;
}
